/* eslint-disable react/no-danger */
import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { shape, any } from 'prop-types';

import Layout from '../components/layout';

export default function Template({ data }) {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <Helmet
        title={`KDE Blog - ${post.frontmatter.title}`}
        meta={[
          { name: 'description', content: post.excerpt },
          { name: 'keywords', content: post.excerpt },
          { property: 'og:title', content: post.frontmatter.title },
          { property: 'og:type', content: 'article' },
          {
            property: 'og:url',
            content: `https://kde.design${post.frontmatter.path}`,
          },
          { property: 'og:image', content: '' }, // TODO: add image
          { property: 'og:description', content: post.excerpt },
          { property: 'og:site_name', content: 'KDE Design' },
          {
            property: 'article:published_time',
            content: new Date(post.frontmatter.date).toISOString(),
          },
          { name: 'twitter:card', content: 'summary' },
          { name: 'twitter:site', content: '@DanyKovacs' },
          { name: 'twitter:title', content: post.frontmatter.title },
          { name: 'twitter:description', content: post.excerpt },
          { name: 'twitter:creator', content: '@DanyKovacs' },
        ]}
      >
        <html lang="en" />
      </Helmet>
      <div>
        <h1>{post.frontmatter.title}</h1>
        <p style={{ color: '#666' }}>{post.frontmatter.date}</p>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
    </Layout>
  );
}

Template.propTypes = {
  data: shape({ markdownRemark: any }).isRequired,
};

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`;
